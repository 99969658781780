$(function () {
  var url = window.location.href;
  if (url.search(/\/ja\//) !== -1) {
    var langUrl = url.replace("/ja/", "/en/");
    $(".language a").attr("href", langUrl);
  } else if (url.search(/\/en\//) !== -1) {
    langUrl = url.replace("/en/", "/ja/");
    $(".language a").attr("href", langUrl);
  } else return false;
});
